window.addEventListener(
    "load",
    function () {
      $("#ac_form_submit").ready(function () {
        $("#ac_form_submit").attr("disabled", true);
      });
  
      $(".ac_up_1").change(function () {
        if ($(this)[0].files && $(this)[0].files[0]) {
          var reader = new FileReader();
  
          reader.onload = function (e) {
            $("#replace_image_1").attr("src", e.target.result);
            $("#replace_image_1").css("display", "flex");
            $(".ac_image_label_1").css("display", "none");
            $('#ac_rmv_1').css('display', 'flex');

          };
          reader.readAsDataURL($(this)[0].files[0]);
          $("#ac_form_submit").attr("disabled", false);
        }
      });

      $(".ac_up_2").change(function () {
        if ($(this)[0].files && $(this)[0].files[0]) {
          var reader = new FileReader();
  
          reader.onload = function (e) {
            $("#replace_image_2").attr("src", e.target.result);
            $("#replace_image_2").css("display", "flex");
            $(".ac_image_label_2").css("display", "none");
            $('#ac_rmv_2').css('display', 'flex');

          };
          reader.readAsDataURL($(this)[0].files[0]);
          $("#ac_form_submit").attr("disabled", false);
        }
      });

      $(".ac_up_3").change(function () {
        if ($(this)[0].files && $(this)[0].files[0]) {
          var reader = new FileReader();
  
          reader.onload = function (e) {
            $("#replace_image_3").attr("src", e.target.result);
            $("#replace_image_3").css("display", "flex");
            $(".ac_image_label_3").css("display", "none");
            $('#ac_rmv_3').css('display', 'flex');

          };
          reader.readAsDataURL($(this)[0].files[0]);
          $("#ac_form_submit").attr("disabled", false);
        }
      });

      $(".ac_up_4").change(function () {
        if ($(this)[0].files && $(this)[0].files[0]) {
          var reader = new FileReader();

          reader.onload = function (e) {
            $("#replace_image_4").attr("src", e.target.result);
            $("#replace_image_4").css("display", "flex");
            $(".ac_image_label_4").css("display", "none");
            $('#ac_rmv_4').css('display', 'flex');

          };
          reader.readAsDataURL($(this)[0].files[0]);
          $("#ac_form_submit").attr("disabled", false);
        }
      });

      $('#ac_rmv_1').on('click', function(e){
        $("#replace_image_1").attr("src", "");
        $("#replace_image_1").css("display", "none");
        $(".ac_image_label_1").css("display", "block");
        $('#ac_rmv_1').css('display', 'none');
      });

      $('#ac_rmv_2').on('click', function(e){
        $("#replace_image_2").attr("src", "");
        $("#replace_image_2").css("display", "none");
        $(".ac_image_label_2").css("display", "block");
        $('#ac_rmv_2').css('display', 'none');
      });

      $('#ac_rmv_3').on('click', function(e){
        $("#replace_image_3").attr("src", "");
        $("#replace_image_3").css("display", "none");
        $(".ac_image_label_3").css("display", "block");
        $('#ac_rmv_3').css('display', 'none');
      });

      $('#ac_rmv_4').on('click', function(e){
        $("#replace_image_4").attr("src", "");
        $("#replace_image_4").css("display", "none");
        $(".ac_image_label_4").css("display", "block");
        $('#ac_rmv_4').css('display', 'none');
      });
    },
    false
  );